import * as React from "react";
import "./VideoHeadline.css"
import {Image} from "react-bootstrap";
import {Card} from "react-bootstrap";
import {TweetVideo} from "../../../../../model/TweetVideo";
import {buildVideoLink} from "../../../../../utils/article/articleUtils";
import VideoDateCountryPane from "../../../../Common/DateCountry/VideoDateCountryPane";
import VideoTitle from "../../../../Common/Title/VideoTitle";
import {Link, useHistory} from "react-router-dom";

interface VideoData {
  video: TweetVideo,
}

const VideoHeadline: React.FC<VideoData> = (props) => {
  const history = useHistory()
  return (
    <React.Fragment>
      <div className="video_headline_title">
        <div onClick={() => history.push('/watch')} className="headline_underline force-cursor-on-div">Watch now</div>
      </div>
      <Card className="headline_box_no_borders-video">
        <div>
          <Link to={`/watch/single/${props.video.tweetVideoId}`} className="headline-link">
            <span className="play-video-headline"/>
          </Link>
        </div>
        <div>
          <Image src={buildVideoLink(props.video.link, true)} fluid className="image_no_border_video"/>
        </div>
        <div>
          <div className="country-card-video-first">
            <div className="LocationTime location-news-list pl-0">
              <span className="country-background">
                <VideoDateCountryPane video={props.video} withDate={false} />
              </span>
            </div>
          </div>
          </div>  
        <Card.Body className="headline_title_background p-2 video-first-page">
          <Card.Title className="text_no_bottom_margin h6 mt-0">
            <VideoTitle title={props.video.title} videoId={props.video.tweetVideoId}/>

          </Card.Title> 
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export default VideoHeadline;
