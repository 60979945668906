import * as React from "react";
import {Container, Col, Row} from "react-bootstrap";
import TrendingPane from "./TrendingPane/TrendingPane";
import {useSelector} from "react-redux";
import {
  getHeadline,
  getHeadlineNews,
  getLatestTweets,
  getTrendingTweets
} from "../../../store/selectors/tweets/tweet.selector";
import {getMainVideo} from "../../../store/selectors/videos/videos.selector";
import BannersHomeLeft from "../../Banners/BannersHomeLeft/BannersHomeLeft";
import BannersHomeRight from "../../Banners/BannersHomeRight/BannersHomeRight";
import Newsletter from "../../Newsletter/Newsletter";
import {getCompanyTags, getPeopleTags, getShowTags} from "../../../store/selectors/trending/trending.selector";
import HeadlineNews from "./HeadlineNews/HeadlineNews";

const MainPage: React.FC = () => {
  const headlineItem = useSelector(getHeadline);
  const headlineNews = useSelector(getHeadlineNews);
  const trendingNews = useSelector(getTrendingTweets);
  const latestNews = useSelector(getLatestTweets)
  const mainVideo = useSelector(getMainVideo)
  const companyTags = useSelector(getCompanyTags)
  const showTags = useSelector(getShowTags)
  const peopleTags = useSelector(getPeopleTags)
  // const headlinesData = headlineItem ? [headlineItem].concat(headlineNews) : [...headlineNews]
  return (
    <React.Fragment>
      <Container className="p-0 sm-news-container mt-0">
        <Row className="pane_center_row p-0 d-flex justify-content-center">
        <BannersHomeLeft />
          <Col xl={{span: 8}} lg={{span: 12}} md={{span: 12}} className="p-0">
            <Row className="trending_main_row pb-0 justify-content-between">
              <HeadlineNews headline={headlineItem} headlines={headlineNews} />
              <TrendingPane
                  latest={latestNews}
                  trending={trendingNews}
                  mainVideo={mainVideo}
                  companyTags={companyTags}
                  showTags={showTags}
                  peopleTags={peopleTags}
              />
            </Row>
          </Col>
          <BannersHomeRight />
        </Row>
      </Container>
    <Newsletter />
    </React.Fragment>
  );
}

export default MainPage;
