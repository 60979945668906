import {Dispatch} from "react";
import {
  loadingRelatedData,
  loadRelatedDataSingleItem,
  loadTweetHeadlines,
  loadTweetLists
} from "../../store/actions/tweets/tweet.actions";
import {
  loadingBannersComplete,
  loadingComplete,
  loadingMainComplete,
  loadingTrendingComplete
} from "../../store/actions/load/load.actions";
import {
  loadingRelatedVideoData,
  loadRelatedDataSingleVideoItem,
  loadVideoHeadlines,
} from "../../store/actions/videos/video.actions";
import {loadAdditionalUserData, loadUser, loadUserFullData} from "../../store/actions/user/user.actions";
import {loadAllBanners} from "../../store/actions/banners/banners.actions";
import {loadTrendingTags} from "../../store/actions/trending/trending.actions";


export async function loadInitial(dispatch: Dispatch<any>): Promise<void> {
  await dispatch(loadUser())
  await dispatch(loadUserFullData())
  await dispatch(loadTweetHeadlines())
  await dispatch(loadingMainComplete(true))
  await dispatch(loadAllBanners())
  await dispatch(loadingBannersComplete(true))
  await dispatch(loadTweetLists(false))
  await dispatch(loadTweetLists(true))
  await dispatch(loadVideoHeadlines())
  await dispatch(loadingTrendingComplete(true))
  await dispatch(loadTrendingTags())
  await dispatch(loadingComplete(true))
}

export async function loadInitialOnSingleNewsOpen(dispatch: Dispatch<any>, newsId: number, ceetv: boolean): Promise<void> {
  await dispatch(loadingRelatedData(true))
  await dispatch(loadRelatedDataSingleItem(newsId, ceetv))
  await dispatch(loadingRelatedData(false))
}

export async function loadInitialOnSingleVideoOpen(dispatch: Dispatch<any>, videoId: number): Promise<void> {
  await dispatch(loadingRelatedVideoData(true))
  await dispatch(loadRelatedDataSingleVideoItem(videoId))
  await dispatch(loadingRelatedVideoData(false))
}

export async function loadAllUserData(dispatch: Dispatch<any>) {
  await dispatch(loadUser())
  await dispatch(loadAdditionalUserData())
}

export async function updateCurrentUser(dispatch: Dispatch<any>): Promise<void> {
  await dispatch(loadUser())
}

export async function updateUserAfterPicture(dispatch: Dispatch<any>): Promise<void> {
  await dispatch(loadUser())
}

