export const tvbizzURL = 'https://tvbizz.net/api';
export const newsLatest = '/news/list';
export const trendingHeadlines = '/pref/trending';
export const trendingHeadlinesRegion = '/pref/trending/regions';
export const trendingLatestData = '/pref/trending/all';
export const trendingTags = '/pref/tags';
export const trendingStartPage = '/news/list?trending=true';
export const headlinesList = '/news/headlines';
export const loadSingleNewsItem = '/news/item/';
export const videoLatest = '/videos/list';
export const videoHeadlinesList = '/videos/headlines';
export const loadSingleVideosItem = '/videos/item/';
export const userLoad = '/users/get';
export const userFullLoad = '/users/getuserdata';
export const userLogin = '/users/login';
export const userRegister = '/users/register';
export const userActivate = '/users/activate';
export const userEditData = '/users/update';
export const userChangePass = '/users/security';
export const userListView = '/users/view';
export const userConsent = '/users/consent';
export const subscriptionGetUser = '/subscription/getcustomer';
export const subscriptionCreate = '/subscription/create';
export const subscriptionActivate = '/subscription/activate';
export const companyData = '/entity/company/';
export const showData = '/entity/show/';
export const tagRelatedItems = '/search/tag?tag=';
export const searchRelatedItems = '/search/search?keyword=';
export const countryRelatedItems = '/search/country?name=';
export const categoryRelatedItems = '/search/category?type=';
export const newsletterSubscribe = '/newsletter/subscribe';
export const updateProfileImage = '/users/upload/image'
export const bannersData = '/banners/list'
export const messagesData = '/messages/list'


