import {Dispatch} from "react";
import axios from "axios";
import {
  trendingHeadlines,
  trendingHeadlinesRegion,
  trendingLatestData,
  trendingTags,
  tvbizzURL
} from "../../../const/urlConst";
import {TrendingItem} from "../../../model/TrendingItem";
import {TrendingCategory} from "../../../model/TrendingCategory";
import {TrendingRegions} from "../../../model/TrendingRegions";

export const LOAD_TRENDING_HEADLINES_EVENT = 'LOAD_TRENDING_HEADLINES';
export const LOAD_TRENDING_NEWS_REGION_EVENT = 'LOAD_TRENDING_NEWS_REGION';
export const LOAD_TRENDING_TAGS_REGION_EVENT = 'LOAD_TRENDING_TAGS_REGION';
export const LOAD_TRENDING_DATA_EVENT = 'LOAD_TRENDING_DATA';
export const LOAD_TRENDING_LOADING_EVENT = 'LOAD_TRENDING_LOADING';
export const LOAD_TRENDING_TAGS_EVENT = 'LOAD_TRENDING_TAGS';

export type LOAD_TRENDING_HEADLINES = typeof LOAD_TRENDING_HEADLINES_EVENT;
export type LOAD_TRENDING_NEWS_REGION = typeof LOAD_TRENDING_NEWS_REGION_EVENT;
export type LOAD_TRENDING_TAGS_REGION = typeof LOAD_TRENDING_TAGS_REGION_EVENT;
export type LOAD_TRENDING_DATA = typeof LOAD_TRENDING_DATA_EVENT;
export type LOAD_TRENDING_LOADING = typeof LOAD_TRENDING_LOADING_EVENT;
export type LOAD_TRENDING_TAGS =  typeof LOAD_TRENDING_TAGS_EVENT;

export interface LoadTweetTrendingHeadlines {
  type: LOAD_TRENDING_HEADLINES,
  headlines: TrendingItem[],
}

export interface LoadTweetRegionTrendingHeadlines {
  type: LOAD_TRENDING_NEWS_REGION,
  newsRegion: TrendingRegions,
}

export interface LoadTweetTrendingCategory {
  type: LOAD_TRENDING_DATA,
  tweets: TrendingItem[],
  tags: TrendingItem[]
  categories: TrendingCategory[],
  loading: boolean,
}

export interface LoadTweetTrendingLoading {
  type: LOAD_TRENDING_LOADING,
  loading: boolean,
}

export interface LoadTagsTrending {
  type: LOAD_TRENDING_TAGS,
  companyTags: TrendingItem[],
  showTags: TrendingItem[],
  peopleTags: TrendingItem[],
}

export interface LoadTagsRegionTrending {
  type: LOAD_TRENDING_TAGS_REGION,
  companyTags: TrendingCategory[],
  showTags: TrendingCategory[],
  peopleTags: TrendingCategory[],
}

export const loadingTrendingData = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: LOAD_TRENDING_LOADING_EVENT,
      loading: true,
    });
  }
}

export const loadTweetTrendingList = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axios.get(`${tvbizzURL}${trendingLatestData}`)
    dispatch( {
      type: LOAD_TRENDING_DATA_EVENT,
      tweets: data.topNews,
      tags: data.topTags,
      categories: data.categories,
    });
  }
}

export const loadTweetTrendingHeadlines = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axios.get(`${tvbizzURL}${trendingHeadlines}`)
    dispatch( {
      type: LOAD_TRENDING_HEADLINES_EVENT,
      headlines: data.topNews,
    });
  }
}

export const loadTweetRegionTrendingList = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axios.get(`${tvbizzURL}${trendingHeadlinesRegion}`)
    dispatch( {
        type: LOAD_TRENDING_NEWS_REGION_EVENT,
        newsRegion: data,
    });
  }
}

export const loadTrendingTags = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axios.get(`${tvbizzURL}${trendingTags}`)
    dispatch( {
      type: LOAD_TRENDING_TAGS_EVENT,
      companyTags: data.companyTags,
      showTags: data.showTags,
      peopleTags: data.peopleTags,
    });
  }
}
